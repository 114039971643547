
html,body {
  margin: 0;
  font-family: 'Lexend', sans-serif !important;
  background-color: #f3f5ff;
  color: #0f0f0f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-layout {
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 70px;
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.125rem;
}

/* Navigation panel: */
.mySidenav {
  align-items: flex-start;
  border-right: 1px solid darkgray;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  text-align: left;
  top: 70px;
  width: 250px;
  z-index: 1;
}
.mySidenav ul {
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: fixed;
  top: 70px;
}
.mySidenav li {
  list-style: none;
  margin: 1rem 0;
}
.mySidenav li a {
  align-items: center;
  color: #0f0f0f;
  display: flex;
  font-size: 14px;
  text-decoration: none;
  margin-left: 1rem;
}

.feedback,
.cafecito {
  display: none;
}

.main-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.footer-container.ul-navigation {
  padding-left: 2rem;
}
.material-icons,
.material-icons-round,
.material-icons-outlined {
  margin-right: 5px;
}
.navlink {
  display: flex;
}
.active {
  color: #ee05f2;
  font-weight: 500;
  transition: all .3s ease-out;
}
/* End Navigation Panel */

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: inherit;
}

.calculator-container,
.feedback-roadmap-container,
.signUp-container,
.login-container,
.registros-container {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.signUp-container,
.login-container,
.registros-container {
  width: 100%;
  height: 100vh;
}

.feedback-roadmap-container,
.roadmap-container {
  height: 100vh;
}

.title-container,
.title-feedback-container,
.title-roadmap-container {
  margin-top: 2rem;
}

.title-page-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .title-page-container h1 a {
  display: flex;
  align-items: center;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title-container,
.form-fields-container {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.title-page-container span {
  font-size: 2rem;
  margin-left: 0.5rem;
}

.title-container {
  background: linear-gradient(0deg, #E6E9FF, #E6E9FF), #E6E9FF;
  border-radius: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
  padding: 24px 69px 24px 96px;
}

.field-container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-icon {
  font-weight: 100;
  cursor: help;
}

.paso-anterior-btn {
  border-radius: 50px !important;
  text-transform: capitalize !important;
}

.MuiBox-root .css-11qjisw {
  display: none;
}

/* Form section: */
.form-resultados-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
}

.form-fields-container {
  display: grid;
  grid-template-columns: repeat(2, 470px);
  grid-gap: 1.5rem;
  margin: auto;
}

.continuar-btn {
  display: flex;
  align-items: center;
  color: #030ba6 !important;
  border-radius: 50px !important;
  font-size: 0.9375rem;
  text-transform: capitalize !important;
}

.continuar-btn img {
  margin-left: 0.625rem;
}

.css-2royc9 {
  padding-top: 0 !important;
}

/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.paso-anterior-btn, */
.stepLabel-container .MuiStepLabel-root .MuiStepLabel-iconContainer .Mui-active {
  color: #030ba6 !important;
  width: 2.5rem;
  height: 2.5rem;
}

.MuiStepLabel-label,
.MuiButtonBase-root,
.MuiInputLabel-root,
.MuiStepIcon-text,
.MuiTooltip-tooltip,
.MuiTypography-root {
  font-family: 'Lexend', sans-serif !important;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.form-button-container button {
  font-family: 'Lexend', sans-serif;
  text-transform: capitalize;
  font-size: 15px;
  height: 46px;
  min-width: 218px;
}

.otros-datos {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.gananciaEstimada-container,
.porcentajeManager-container {
  display: flex;
}

.input-gananciaEstimada {
  width: 80px;
}
.input-gananciaEstimada div input {
  width: 80px;
}

.value-ganancia-estimada {
  margin-left: 1rem;
}

.ganancias-manager-container {
  display: flex;
  margin-bottom: 3rem;
}


.css-i44wyl {
  width: 100px;
}

/* .css-1o6z5ng {
  max-width: 4rem;
} */

.publico-cortesias-container {
  display: flex;
}

.ganancias-container,
.publico-container {
  margin-right: 4rem;
}

.cortesia-field-container label {
  display: block;
  margin: 0;
}

.MuiFormControlLabel-label {
  color: #0f0f0f;
  font-family: 'Lexend', sans-serif !important;
  font-weight: bold !important;
}

.cortesias-input-container .MuiInputBase-input {
  width: 3rem;
}

.resultados-container {
  border-radius: 0.4vw;
  background-color: #e6e9ff;
  border: none;
  padding: 0 3rem;
  min-width: 350px;
}

.managerInfo-container--cortesia {
  margin-right: 2rem;
}

.porcentaje-manager-container {
  margin-right: 2rem !important;
}

.field-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1rem 1rem 1rem 0;
  width: fit-content;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  min-width: 400px;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 20px;
}

.css-14yr603-MuiStepContent-root,
.css-8t49rw-MuiStepConnector-line {
  border-color: #cbc5e8 !important;
}

.css-i4bv87-MuiSvgIcon-root path {
  color: #030ba6;
}

.field-container h4 {
  margin-top: 0;
}

.field-container:hover,
.otros-datos:hover {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3), 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
}

.field-container:focus {
  box-shadow: 0 0 0 .15vw #ee05f2;
}

.subfields-container {
  display: flex;
}

.subfield {
  margin-right: .3125rem;
  margin-top: 0.5rem;
}

.subfield .MuiFormControl-root .MuiInputBase-root,
.porcentajeManager-container .MuiFormControl-root .MuiInputBase-root {
  border-radius: 20px !important;
}

h4 {
  margin-bottom: 0;
}

.input input {
  font-family: "Lexend" !important;
  font-weight: 500;
  font-size: 14px;
  width: fit-content;
  transition: .4s;
}

.quantity-container input {
  max-width: 3rem;
}

/* .value-container input,
.total-container input {
  max-width: 4rem;
} */

.value-container input::-webkit-inner-spin-button,
.quantity-container input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.value {
  width: 150px;
}

.input:hover {
  border-radius: 20px;
}

.input:focus {
  box-shadow: 0 0 0 .15vw #ee05f2;
}

.input-moneda-container {
  display: flex;
  align-items: baseline;
}

.total-container {
  width: 100px;
}

.total label,
.total input,
.input-pesos {
  color: #ee05f2 !important;
  font-weight: bold !important;
}

.pesos,
.porcentaje {
  color: #0f0f0f;
  font-size: 1rem;
  font-weight: bold;
}

.pesos {
  margin-right: 0.3rem;
}

.porcentaje {
  margin-left: 0.3rem;
}

.valorManager-container {
  height: 1rem;
}

.pesosManager-result {
  margin-top: 0.4rem;
}

.value-evento,
.precio-boleta {
  color: #ee05f2;
  font-size: 1rem;
  font-weight: bold;
}

.submit-btn {
  background-color: #ee05f2 !important;
  border-radius: 50px !important;
  color: #fff;
  margin-top: 2rem !important;
}
.submit-btn img {
  margin: 0 0.625rem;
  color: #fff;
}
/* End Form Section */


/* Costo Show */
.costo-concierto-ganancias-container {
  display: flex;
}

.resultados-subcontainer {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

.resultados-subcontainer h1 {
  align-self: flex-end;
}

.ganancias-promotor-container {
  display: flex;
  flex-direction: column;
}

.ganancia-estimada-container {
  margin-right: 3rem;
  margin-bottom: 3rem;
}

.value-show {
  color: #ee05f2;
  font-size: 2rem;
}

.value-show span {
  color: #ee05f2;
  font-size: 2rem;
}

/* Fin Costo Show */

/* iframes */
.iframe-formulario-feedback,
.iframe-prueba {
  width: 100%;
  height: 100%;
}

/* Fin iframes */

.boton-cafe-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boton-donar-cafe {
  align-self: center;
  background-color: #ee05f2;
  border-radius: 50px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  color: white;
  margin: 3rem;
  padding: 15px 20px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 200px;

}

.texto-donar-cafe {
  font-weight: 700;
}

/* Sign Up & Login: */

.signUp-container,
.login-container {
  background-color: #f3f5ff;
  width: 100%;
}
.LockOutlinedIcon-signUp-container {
  background-color: #ee05f2 !important;
}

.error-msg-span {
  color: crimson;
  font-size: 14px;
}

/* End Sign Up & Login */

.container-link-descarga {
  margin-right: 2rem;
}



/* Mobile */

@media (max-width: 950px) {
  .main-layout {
    flex-direction: column;
    /* padding: 1rem; */
  }
  .main-container {
    margin-top: 60px;
    width: 100%;
  }
  .main-container-background {
    background-color: rgba(0,0,0,0.4);
  }
  .mySidenav {
    background-color: #F3F5FF;
    height: 0;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transition: 0.5s;
    width: 0;
    z-index: 2;
  }
  .navbar-mobile-activa {
    height: 100% !important;
    width: 250px;
  }
  .mySidenav ul {
    position: relative;
    top: 60px;
  }

  .mantracks-logo {
    padding-left: 0 !important;
  }
  .calculator-container {
    justify-content: flex-start;
    padding: 0;
    width: 100%;
  }
  .calculator-container,
  .feedback-roadmap-container,
  .signUp-container,
  .login-container {
    padding: 0;
  }

  /* .title-container,
  .title-feedback-container,
  .title-container,
  .title-roadmap-container {

    width: 100%;

  } */

.step .MuiStepContent-root {
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: none !important;
}

  .title-page-container,
  .title-feedback-container h1,
  .title-roadmap-container h1 {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .form-resultados-container {
    margin: 2rem auto;
    width: 95%;
  }

  .field-container {
    width: 95%;
    padding: 0.5rem;
    margin: auto;
    min-width: 350px;
  }
  .subfields-container {
    flex-wrap: wrap;
    align-items: center;
  }
  .subfield {
    justify-content: center;
    margin-right: 0.4rem;
    margin-top: 1rem;
  }

  .total-container {
    width: 40%;
  }

  .form-button-container {
    justify-content: center;
  }

  .form-fields-container {
    grid-template-columns: repeat(1, 1fr);
    margin: 0;
  }
  .ganancias-manager-container,
  .publico-cortesias-container {
    flex-direction: column;
  }
  .ganancias-container,
  .publico-container {
    margin: 1rem 0;
  }
  .cortesia-field-container {
    margin: auto;
    width: 4.375rem;
  }
  .title-container {
    padding: 24px 20px;
    margin-bottom: 2rem;
  }

  .stepLabel-container {
    width: 90%;
  }
  .form-container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .mySidenav {
    width: 100%;
  }
}



/* End Mobile */


.header-container {
  align-items: center;
  background: #E6E9FF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 999 !important;
}

.mantracks-logo {
  padding-left: 40px;
  height: 40px;
  width: 187px;
}

.open-close-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F3F5FF;
  border-color: transparent;
  border-radius: 50px;
  color: #888888;
  height: 40px;
  width: 40px;
}

.pending-icon,
.close-icon {
  border-color: transparent;
  color: #888888;
  margin-right: 0;
  text-align: center;
}

.language-selector {
  background-color: #E6E9FF;
  border: none;
  font-family: Lexend;
}

.language-selector option {
  border: none;
}

.navbar-header {
  margin-right: 2rem;
}

@media (max-width: 950px) {
  .header-container {
    padding: 0 1rem;
    width: -webkit-fill-available;
  }
  .logo-container {
    padding-top: 5px;
  }
  .mantracks-logo {
    height: 34px;
    width: 170px;
  }
  .open-close-navbar {
    text-align: end;
  }
}


.calculators-title,
li.ticketizador {
  margin-bottom: 0;
}

li.roadmap {
  margin-top: 50px;
}
.roadmap .navlink {
  margin-left: 0;
}
.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background: #E6E9FF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: fixed;
  bottom: 0;
}

.footer-container p {
  border-right: 0.5px solid #0F0F0F;
  padding-right: 2rem;
  font-size: 13px;
  letter-spacing: 1px;
}

.footer-container a {
  text-decoration: none;
  color: #0f0f0f;
}

.aviso-legal-link {
  padding: 0 2rem;
  font-size: 13px;
  letter-spacing: 1px;
}

.juliodev-text {
  border-left: 1px solid black;
  border-right: none !important;
  padding-left: 1rem;
}

.footer-container a:hover {
  color: #ee05f2;
}

.footer-container-left-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2.5rem;
}

.enlaces-footer {
  display: flex;
  align-items: center;
}

.footer-container-feedback-btn {
  padding-right: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

/* Mobile */
@media (max-width: 460px) {
  .footer-container {
    align-items: center;
    height: 92px;
    margin-top: 2rem;
    width: 100%;
    font-size: 13px;
  }
  .footer-container-left-text {
    width: 80%;
    padding-left: 1.5rem;
  }
  .footer-container-feedback-btn {
    padding-right: 1.5rem;
  }
  .footer-container p {
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
  }
  .footer-container-feedback-btn a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .enlaces-footer {
    flex-direction: column;
  }
  .aviso-legal-link {
    padding: 0 0 0 1rem;
  }
  .juliodev-text {
    border: none;
  }
}
/* Fin Mobile */